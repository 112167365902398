// ES6/7 polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const { userAgent } = window.navigator;
const IE = /Edge\/\d./i.test(userAgent) || /MSIE 10/i.test(userAgent) || /rv:11.0/i.test(userAgent);

if (IE) {
  /**
   * Loads the the polyfills if IE 10, 11 or Edge are detected
   */
  try {
    import(/* webpackChunkName: "ie-polyfills" */ './ie-polyfills'); // eslint-disable-line no-unused-expressions
  } catch (e) {
    console.error('Could not get polyfills ::', e);
  }
}
