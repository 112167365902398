import logo from 'images/uu_logo.svg';
import mapIcon from 'images/map.svg';
import listIcon from 'images/list.svg';
import backIcon from 'images/back.svg';

export default `
  <header class='header'>
    <h3 class='h3 light header-back hide'>
      <img src='${backIcon}' class='back-icon' alt='back' />Back to search
    </h3>
    <img class='logo' src='${logo}' alt='logo' />
    <img class='header-map-icon' alt='map' src='${mapIcon}' />
    <img class='header-list-icon' alt='list' src='${listIcon}' />
  </header>
`;
