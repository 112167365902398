import './public-path';

import './polyfills';
import { parseQuery, render, getEl } from 'utils/general';
import { header } from 'components';
import logo from 'images/uu_logo-blue.svg';

const appRoot = getEl('.app-root');

/**
 * Initializes the app
 */
async function init() {
  const { location } = window;
  const queryString = parseQuery(location.search);
  const { program, provider } = queryString;
  const sharePage = program && provider;

  // If we just want the share page, fetch provider and program asap
  if (sharePage) {
    try {
      const { default: shareEntry } = await import(/* webpackChunkName: 'share-bundle' */ './share-page-entry');
      shareEntry(appRoot, program, provider);
    } catch (e) {
      console.error('Error loading share script ::', e);
    }
  // Check if we should load the app, otherwise we can 404
  } else if (location.pathname === '/') {
    try {
      const { default: mainEntry } = await import(/* webpackChunkName: "main-bundle" */ './main-app');
      mainEntry(appRoot);
    } catch (e) {
      console.error('Error loading app script ::', e);
    }
  } else { // 404
    console.error('Uknown path :: 404 ::', location.pathname);
    render(
      appRoot, `
        ${header}
        <div class='container-404'>
          <h1 class='h1'>404 - Page not found</h1>
          <img class='img' src='${logo}' alt='Unite Us Logo' />
        </div>
      `,
    );
  }
}

init();
