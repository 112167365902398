import _ from 'lodash';

export default function getMarkerScheme(addresses = []) {
  // rebuild marker to prevent appending the key name "center" to the marker descriptor string
  // const marker = { ...rest };
  const marker = {
    icon: 'https://uniteus-io-assets.s3.amazonaws.com/app-client/location-markers/location-marker-red.png',
    scale: '2',
  };

  const markersLatLngs = addresses.reduce((markersString, curr) => {
    const address = `${curr.latitude},${curr.longitude}`;

    return [address, markersString].join('|');
  }, '');

  const markerStrings = _.reduce(marker, (markerParams, value, key) => {
    // guarding against empty values, but allowing 0 as a valid vlaue
    if (!_.isEmpty(value) || _.isNumber(value)) {
      markerParams.push(`${key}:${value}`);
    }

    return markerParams;
  }, []);

  const markerDescriptor = markerStrings.join('|');

  return encodeURI(`${markerDescriptor}|${markersLatLngs}`);
}
