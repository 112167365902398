import close from 'images/close-red.svg';

export default function () {
  return `
    <div class="group-header">
      <h1 class='h1'>Filters</h1>
      <div class='clear-filters hide'>
        <img class='icon' src='${close}' alt='x' />
        <span class='clear-filter-text'>clear filters</span>
      </div>
    </div>
  `;
}
