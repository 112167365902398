export const { document: doc } = window;

export const getEl = (selector, parent = doc) => parent.querySelector(selector);

export const getElAll = (selector, parent = doc) => parent.querySelectorAll(selector);

export const createEl = ({ type, className, text, data }) => {
  const el = doc.createElement(type);

  if (className || text) {
    el.className = className;
    el.innerHTML = text;
  }

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      el.dataset[key] = value;
    });
  }

  return el;
};

export const getCurrentTime = () => Math.floor((new Date()).getTime() / 1000);

export const getValidExternalUrl = (url) => {
  let newUrl = url;

  if (url.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
    newUrl = `//${url}`;
  }

  return newUrl;
};

export const arrayToObject = (array, keyField) => array.reduce((obj, item) => {
  const newObj = obj;

  newObj[item[keyField]] = item;

  return newObj;
}, {});

export const render = (el, str = '') => el.innerHTML = str;

export const paginate = ({ array, meta }) => {
  const chunkedArr = [];

  for (let i = 0; i < meta.page.total_pages; i += 1) {
    if (i === meta.page.number - 1) {
      chunkedArr[i] = array;
    } else {
      chunkedArr[i] = [];
    }
  }

  return {
    programsToRender: array,
    paginatedItems: chunkedArr,
    totalPages: meta.page.total_pages - 1,
  };
};

const PHONE_FORMATS = [
  // "+15556667777" or with extension ("+15556667777 1234" or "+155566677771234")
  /^\+[0-9]([0-9]{3})([0-9]{3})([0-9]{4})\s?([0-9]*)/,

  // "5556667777" or with extension
  /^([0-9]{3})([0-9]{3})([0-9]{4})\s?([0-9]*)/,
  //
  // "6667777" minimum 7 digits
  /^([0-9]{3})([0-9]{4})/,
];

function isValidMinimum(match) {
  return match && match[0].length === 7;
}

export function formatPhoneNumber(phoneNumber) {
  if (phoneNumber) {
    for (let i = 0; i < PHONE_FORMATS.length; i++) { // eslint-disable-line no-plusplus
      const match = phoneNumber.match(PHONE_FORMATS[i]);

      if (isValidMinimum(match)) {
        return `${match[1]}-${match[2]}`.trim();
      }
      if (match) {
        return `${match[1]}-${match[2]}-${match[3]} ${match[4]}`.trim();
      }
    }
  }

  return '';
}

export function removeDuplicatedProviders(programsToRender) {
  // Get all the provider IDs associated with the programs
  const providerIDsArr = programsToRender.map((p) => p.relationships.provider.data.id);

  // Remove duplicate providers as we don't need them to map to the correct programs and we can loop less
  const providerIDs = [...new Set(providerIDsArr)];

  return providerIDs;
}

export const showNode = (node) => node.classList.remove('hide');

export const hideNode = (node) => node.classList.add('hide');

export const hideNodes = (nodes) => [...nodes].forEach((el) => hideNode(el));

export const showNodes = (nodes) => [...nodes].forEach((el) => showNode(el));

export const makeVisible = (node) => node.classList.remove('invisible');

export const makeInvisible = (node) => node.classList.add('invisible');

export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

// const fetchRetry = async (url, n = 3) => {
//   try {
//       return await fetch(url)
//   } catch(err) {
//       if (n === 1) throw err;
//       return await fetchRetry(url, n - 1);
//   }
// };

export const fetchWrapper = async (url, n = 3) => {
  const { endpoints } = window.uniteus;
  const { API } = endpoints;
  // For fetchTimeout = 8000ms we tested that Fast 3G returns data without retrys
  const fetchTimeout = 8000;
  let didTimeOut = false;

  const timeout = setTimeout(() => {
    didTimeOut = true;
    throw new Error('Request timed out');
  }, fetchTimeout);

  try {
    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${API.key}`,
      },
    });
    clearTimeout(timeout);
    return (!didTimeOut && res.ok) ? res : new Error(res.statusText);
  } catch (e) {
    return (n === 1) ? new Error(e) : fetchWrapper(url, n - 1);
  }
};
/**
 *
 * @param {Element} focusEl The node (container) that should be in focus
 * @param  {...Elements} loseFocusEls The nodes that should be out of focus
 */
export const setAppFocus = (focusEl, ...loseFocusEls) => {
  const { classList } = focusEl;
  const app = getEl('.app-root');
  const header = getEl('.header', app);
  const backButton = getEl('.header-back', header);
  const mapIcon = getEl('.header-map-icon', header);
  const listIcon = getEl('.header-list-icon', header);

  [...loseFocusEls].forEach((el) => el.classList.remove('in-focus'));
  hideNodes([mapIcon, listIcon, backButton]);
  listIcon.classList.remove('right-align');
  focusEl.classList.add('in-focus');

  if (classList.contains('map')) {
    showNode(listIcon);
    showNode(backButton);
    listIcon.classList.add('right-align');
  } else if (classList.contains('filter-container')) {
    showNode(mapIcon);
    showNode(listIcon);
  } else if (classList.contains('list-pagination-container')) {
    showNode(mapIcon);
    showNode(backButton);
  }
};

export const clearInputValue = (element) => getEl(element).value = '';
