import {
  locationStr, servicesStr,
  phoneStr, emailStr,
  descriptionStr, websiteStr,
  eligibilityStr,
} from 'utils/formatter';
import closeIcon from 'images/close.svg';
import printIcon from 'images/print-icon.svg';
import shareIcon from 'images/share-icon.svg';
import { staticMap } from '../static-map';

export default function (program, index) {
  const { attributes: programAttributes, id, locations, serviceTypes, provider = {} } = program;
  const { name, eligibility_text, description } = programAttributes;
  const { attributes: providerAttributes, id: providerId } = provider;
  const { name: providerName, website_url: providerWebsiteUrl } = providerAttributes;
  const locationAttributes = locations ? locations.map((location) => location.attributes) : [];

  return `
    <section class='drawer' data-id=${id} data-index=${index}>
      <div class='drawer-header'>
        <div class='drawer-header-content'>
          <div class='drawer-header-name'>
            <h1 class='h1 name'>${name}</h1>
            <div class='drawer-header-provider-name'>${providerName}</div>
          </div>
          <div class='program-print-share'>
            <a role='button' class='print-btn'>
              <img class='print print-icon' src='${printIcon}' alt='print' />
              <div class='print print-text'>Print</div>
            </a>

            <button 
              type='button' 
              class='button share share-btn'
              data-url='${window.uniteus.endpoints.server}?program=${id}&provider=${providerId}'
            >
              <img class='share-icon' src='${shareIcon}' alt='share' />
              Share
            </button>
          </div>
        </div>
        <img src='${closeIcon}' alt='x' class='close' />
      </div>
      <div class='info-container hide print-show'>
        <article class='static-map-print drawer-item hide print-show'>
          ${staticMap(locationAttributes)}
        </article>
      </div>
      <div class='info-container about-container'>
        <article class='drawer-item'>
          <div class='about-container'>
            <h5 class='about'>About</h5>
          </div>
          <div class='program-drawer-item'>
            <div class='service-types-container'>
              ${servicesStr(serviceTypes)}
            </div>
            ${programAttributes.phone_numbers || programAttributes.email_addresses || programAttributes.website_url ?
            (`<div class='contact-info'>
              <div>
                <h6 class='program-contact-info'>Program Contact Info</h6>
                ${phoneStr(programAttributes.phone_numbers)}
                ${emailStr(programAttributes.email_addresses)}
                ${websiteStr(programAttributes.website_url, 'Program')}
              </div>
            </div>`
            ) : ''}
            <div class='program-description-container'>
              <h6 class='program-description__description'>Description</h6>
              ${descriptionStr(description)}
            </div>
          </div>
        </article>
      </div>
      ${locations ? (`
        <div class='info-container'>
          <article class='drawer-item'>
            <div class='locations-container'>
              <h5 class='locations'>${locations.length > 1 ? `${locations.length} Locations` : '1 Location'}</h5>
            </div>
            <div class='program-drawer-item'>
              <div class='location-contact-info'>
                ${locationStr({ locations: locationAttributes, showHours: true })}
              </div>
            </div>
          </article>
        </div>
      `) : null}
      <div class='info-container about-container'>
        <article class='drawer-item eligibility'>
          ${eligibilityStr(eligibility_text)}
        </article>
      </div>
      <div class='info-container'>
        <article class='drawer-item'>
          <div class='provider-container'>
            <h5 class='provider-title'>Organization Contact Info</h5>
          </div>
          <div class='contact-info' data-id='${providerId}'>
            <div class='provider-text'>
              <h6 class='h6'>${providerName}</h6>
              ${phoneStr(providerAttributes.phone_numbers)}
              ${emailStr(providerAttributes.email_addresses)}
              ${websiteStr(providerWebsiteUrl, 'Provider')}
            </div>
          </div>
        </article>
      </div>
    </section>
  `;
}
