const getButtonStr = (i, totalPages) => `
  <span
    class='span ${i === 0 ? 'selected' : ''} ${(i >= 5 && i !== totalPages) ? 'hide' : ''}'
    data-idx='${i}'>${i + 1}
  </span>
  ${i === 5 && totalPages > 4 ? '<span class="ellipses">...</span>' : ''}
`;

export default function ({ paginatedItems = [], totalPages }) {
  return totalPages !== 0 ?
    `
      <div class='pagination-listener-container'>
        <span class='span previous invisible' data-prev=true>Previous</span>
          ${paginatedItems.map((p, idx) => getButtonStr(idx, totalPages)).join('')}
        <span class='span next' data-next=true>Next</span>
      </div>
    ` :
    '';
}
