import close from 'images/close-icon-lightBlue.svg';
import search from 'images/search-lightBlue.svg';

const TITLE = 'Program or Organization Name';
export default `
  <div class='text-search-container'>
    <label class='label filter-label' title="${TITLE}" for='searchInput'>${TITLE}</label>
    <input
      aria-label='search' type='text' name='searchInput' id='searchInput'
      class='input search' placeholder='Search' autocomplete='off'
    />
    <img class='clear-program-btn hide' src='${close}' />
    <img class='search-text hide' src='${search}' />
  </div>
`;
