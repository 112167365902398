import printIcon from 'images/print-icon.svg';

export default (program) => `<div class='share-header'>
  <div class='share-header-content'>
    <div class='share-header-name'>
      <h1 class='h1 name'>${program.attributes.name}</h1>
      <div class='share-header-provider-name'>${program.provider.attributes.name}</div>
    </div>
    <a role='button' class='print-btn'>
      <img class='print print-icon' src='${printIcon}' alt='print' />
      <div class='print print-text'>Print</div>
    </a>
  </div>
</div>`;
