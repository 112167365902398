import target from 'images/target.svg';
import close from 'images/close-icon-lightBlue.svg';

const { geolocation: html5Geolocation } = window.navigator;

export default () => `
  <div class='location-search-container'>
    <label class='label filter-label'>Address</label>
    <input
      aria-label='location search' id='filterInputLocationSearch' name='filterInputLocationSearch'
      class='input filter-input location filter-input-location-search' placeholder='Address'
    />
    <img class='clear-location-btn hide' src='${close}' />
    <img src='${target}' class='my-location-btn ${!html5Geolocation ? 'hide' : ''}' alt='[+]' />
  </div>
`;
