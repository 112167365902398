export const CLOSED_DAY = 'Closed';
export const ABBREVIATED_DAYS = {
  sunday: 'Sun',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
};
export const FAUX_MIDNIGHT = 1439;
export const NOON = 720;
