import getMarkerScheme from './get-marker-scheme';

const { endpoints } = window.uniteus || {};
const { googleStaticMaps: GOOGLE_STATIC_MAPS_API_URL, googleMapsAPIkey: GOOGLE_MAPS_API_KEY } = endpoints;

const STATIC_API_URL = `${GOOGLE_STATIC_MAPS_API_URL}?key=${GOOGLE_MAPS_API_KEY}`;
const SIZE = '640x300';
export default function staticMap(addresses) {
  const markers = `&markers=${getMarkerScheme(addresses)}`;
  const mapSize = `&size=${SIZE}`;
  const mapScale = '&scale=2';
  const mapSrc = `${STATIC_API_URL}${markers}${mapSize}${mapScale}`;

  return `
    <img
      alt='map'
      class='static-map-img'
      src='${mapSrc}'
    />
  `;
}
