import filterHeaderClear from './filter-header-clear';
import location from './location';
import accordion from './accordion';
import textSearch from './text-search';
import accordionHeader from './accordion-header';

export default function () {
  return `
    <div class='filter-container in-focus'>
      ${filterHeaderClear()}
      ${textSearch}
      ${location()}
      ${accordionHeader}
      ${accordion()}
    </div>
  `;
}
