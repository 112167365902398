import { formatPhoneNumber, getValidExternalUrl } from 'utils/general';
import locationIconBlue from 'images/location-icon-blue.svg';
import emailIcon from 'images/mail-icon.svg';
import phoneIcon from 'images/phone.svg';
import websiteIcon from 'images/website-icon.svg';
import { slice } from 'lodash';
import formatTimeOfDay from './formatTimeOfDay';
import { ABBREVIATED_DAYS, CLOSED_DAY } from './constants';

export const addressStr = (locations) => `
  ${locations.map(({ line_1, city, state, postal_code }) => {
  const line1 = line_1 ? `${line_1},` : '';

  return (`
  <address class='p dark address'>
    <img class='location-icon' src='${locationIconBlue}' alt='location' />
    ${line1 || ''} ${city || ''} ${state || ''} ${postal_code || ''}
  </address>
`);
}).join('')}`;

export const servicesStr = (serviceTypes = []) => {
  const parentTable = [];
  const childrenTable = [];
  let addService = '';
  let servicesList = '';

  serviceTypes.forEach(({ children = [], name: pName }) => {
    const serviceTable = { parent: [], children: [] };
    if (children.length) {
      serviceTable.parent.push(pName);
      parentTable.push(pName);
      children.forEach(({ name: cName }) => {
        serviceTable.children.push(cName);
        childrenTable.push(serviceTable);
      });

      // remove duplicated services types
      const filteredChildrenTable = childrenTable.filter((el, index) => childrenTable.indexOf(el) >= index);
      serviceTypes.forEach(({ name: rName }) => {
        filteredChildrenTable.forEach((el) => {
          if (el.children.includes(rName) && el.parent.includes(pName)) {
            const parentServiceToAdd = `<div class='p services-group'><span class='service-group-name'>${pName}</span>`;
            if (addService.includes(parentServiceToAdd)) {
              addService += `<p class='p service'>${rName}</p>`;
            } else {
              addService += `${parentServiceToAdd}: <p class='p service'>${rName}</p>`;
            }
          }
        });
      });
      addService += '</div>';
    }
    servicesList = addService;
  });

  return `
  <div class='container service-types-container'>
    <h6 class='h6 services-label'>Service Types</h6>
      ${servicesList}
  </div>`;
};

export const providerStr = (name) => `
  <p class="p dark provider-string"><span class='span'>${name}</span></p>
`;

export const phoneStr = (phoneNumber, locationCard = false) => `
  ${phoneNumber ? `
    ${phoneNumber.map(({ country_code = '', phone_number = '', extension = '', phone_type = '' }) => `
      <p class='p phone'>
        <a class='a' href='tel:+${country_code}${phone_number}'>
          ${locationCard ? '' : `<img class='phone-icon' src='${phoneIcon}' alt='phone' />`}
          <span>
            <span class='phone-type'>${phone_type ? `${phone_type}: ` : ''}</span>
            ${formatPhoneNumber(phone_number)} ${extension ? `ext. ${extension}` : ''}
          </span>
        </a>
      </p>
    `).join('')}
  ` : ''}
`;

export const descriptionStr = (description) => `
  ${description ? `
    <div class='description-container container'>
      <p class='p dark description'>${description}</p>
    </div>
  ` : ''}
`;

export const websiteStr = (websiteUrl, type) => `
  ${websiteUrl ? `
  <p class='p website'>
    <a
      class='a print-hide website-link ${type.toLowerCase()}' target='_blank'
      href='${getValidExternalUrl(websiteUrl)}'
    >
      <img class='website-icon' src='${websiteIcon}' alt='website' />
      <span>${websiteUrl}</span>
    </a>
  ` : ''}
`;

export const eligibilityStr = (eligibilityText) => `
  ${eligibilityText ? `
    <div>
      <div class='eligibility-container'>
        <h5 class='h5 eligibility'>Eligibility</h5>
      </div>
    <div class='p eligibility-text'>${eligibilityText}</div>
  </div>` : ''}
`;

export const emailStr = (emailAddresses = []) => {
  let emailAddressesArr = emailAddresses;

  if (emailAddressesArr.length && typeof emailAddresses[0] !== 'string') {
    emailAddressesArr = emailAddresses.map((email) => email.email_address);
  }

  return (`
    ${emailAddressesArr.map((email_address) => `
      <p class='p email'>
        <a class='a' href='mailto:${email_address}'>
          <img class='email-icon' src='${emailIcon}' alt='email' />
          <span>${email_address}</span>
        </a>
      </p>
    `).join('')}
  `
  );
};

const getHours = (dayOfWeek, hours) => {
  const hoursOfOperation = hours[dayOfWeek];

  let openClose = `<div>${CLOSED_DAY}</div>`;

  if (hoursOfOperation !== undefined) {
    openClose = hoursOfOperation.map((v) => `<div>${formatTimeOfDay(v.opens)} - ${formatTimeOfDay(v.closes)}</div>`);
  }

  if (Array.isArray(openClose)) {
    return openClose.join('');
  }
  return openClose;
};

export const locationStr = ({ locations, showHours = false }) => (`${locations.map(({
  line_1, city, state, postal_code,
  name = null, phone_numbers = [], email_addresses = [], hours,
}) => {
  const daysOfTheWeek = Object.keys(ABBREVIATED_DAYS);
  const d = new Date();
  const todayIndex = d.getDay();
  const weekFromToday = [
    ...slice(daysOfTheWeek, todayIndex),
    ...slice(daysOfTheWeek, 0, todayIndex),
  ];
  const formattedCity = (city && `${city}, `) || '';

  return (`
    <address class='p dark location-container'>
      <div class='location'>
        <img class='location-icon' src='${locationIconBlue}' alt='location' />
        <div class='location-information'>
          ${name === null ? '' : `
            <span class='location-name'>${name} <br/></span>
          `}
          ${line_1 || ''} ${formattedCity} ${state || ''} ${postal_code || ''}
          ${phoneStr(phone_numbers, true)}
          ${email_addresses ? `
            ${email_addresses.map((email) => `
              <a class='a' href='mailto:${email}'>${email}</a>
            `).join('')}
          ` : ''}
        </div>
      </div>

      ${(hours && showHours) ? `
        <div class='hours-of-operation-container'>
          <div class="hours-of-operation-header">Hours today:</div>
          <div>
            ${weekFromToday.map((dayOfWeek) => (`
              <div class='day-label'>${ABBREVIATED_DAYS[dayOfWeek]}</div>
              <div class='day-hours'>${getHours(dayOfWeek, hours)}</div>
            `)).join('')}
          </div>
        </div>` : ''}
    </address>
  `);
}).join('')}`);
