import carrot from 'images/play.svg';

export default function (serviceTypesArr) {
  return `
    <div class='service-type-accordion'>
      ${serviceTypesArr ? serviceTypesArr.map(({ name, children, id }) => `
        <ul class='accordion' data-id='${id}'>
          <h3 class='accordion-heading expand-handler'>
            <img alt='expand' class='carrot expand-handler' src='${carrot}' />${name}
          </h3>
          ${children ? `
            <li class='checkbox-container' data-id="${id}" data-name="${name}">
              <input
                id="${id}" class='input checkbox all' type="checkbox"
                data-id="${id}" data-name="${name}" name="all"
              />
              <label class='label all' for="${id}">Select All</label>
            </li>
            ${children.map(({ name: cName, id: cId }) => `
              <li class='checkbox-container' data-parentid="${cName}" data-id="${cId}" data-name="${cName}">
                <input
                  id="${cId}" class='input checkbox' type="checkbox"
                  data-parentid="${id}" data-id="${cId}" data-name="${cName}" name="${cId}"
                />
                <label class='label' for="${cId}">${cName}</label>
              </li>
            `).join('')}
          ` : ''}
        </ul>
      `).join('') : ''}
    </div>
  `;
}
