import { locationStr, servicesStr, providerStr } from 'utils/formatter';

const loadingString = () => {
  let str = '';

  for (let i = 0; i < 5; i += 1) {
    str += `
      <article class='list-item loading'>
        <h3 class='h3 name'></h3>
        <p class='p'>
          Provided by
          <span class='span'></>
        </p>
        <p class='p'><span class='span'></></p>
        <p class='p'><span class='span'></></p>
        <p class='p'>
          Services: <span class='span'></span>
        <p>
      </article>
    `;
  }

  return str;
};

export default ({
  loading = false, programsToRender = [], totalResults = 0, currentPage = 0, formattedLocation = '',
}) => `
  <h2 class='h2 list-header' data-index='${currentPage}'>
    ${loading ? '' : (`
      <span>
        ${totalResults !== 1 ? `${totalResults} Results` : '1 Result'}
        ${formattedLocation ? `near ${formattedLocation}` : ''}
      </span>
    `)
  }
  </h2>
  <div class='list-listener-container'>
  ${loading ? loadingString() :
    programsToRender.length ? programsToRender.map((program, index) => {
      const { attributes, id, serviceTypes, provider = {}, locations } = program;
      const { attributes: providerAttributes } = provider;
      const { name: providerName } = providerAttributes;
      const { name } = attributes;
      const locationAttributes = locations ? locations.map((location) => location.attributes) : [];

      return programsToRender[index + 1] ? `
        <article class='list-item' data-id=${id} data-index=${index}>
          <h5 class='h5 name'>${name}</h5>
          ${providerStr(providerName)}
          ${servicesStr(serviceTypes)}
          ${locationStr({ locations: locationAttributes })}
        </article>
        ` : `
          <article class='list-item' data-id=${id} data-index=${index}>
            <h3 class='h3 name'>${name}</h3>
            ${providerStr(providerName)}
            ${servicesStr(serviceTypes)}
            ${locationStr({ locations: locationAttributes })}
          </article>
          <div class='spacer'></div>
        `;
    }).join('') :
      `<article class='list-item no-results'>
    <p>We're sorry. There were no results for your selections. Please select another Service Type to continue.</p>
  </artcle>`}
`;
